export default {
  black: "#000000",
  blue: "#349CEE",
  blueDark: "#1684D9",
  blueLight: "#46C2F0",
  blueLightest: "#b5e3f5",
  frankBlue: "#00d7ff",
  frankGreen: "#a6ff76",
  frankPink: "#ff6bf0",
  frankPurple: "#cc2cfd",
  gray: "#7d7979",
  grayLight: "#D1D6E1",
  grayLightest: "#f7f7f7",
  green: "#0b880b",
  red: "#c00d1e",
  redDark: "#a70005",
  white: "#ffffff",
  yellow: "#c5ba25",
};
